import React from "react"
import SiteHeader from "../../components/SiteHeader"
import Footer from "../Footer"

const HomepageLayout = ({ pageNumber, location, children }) => (
  <div>
    <SiteHeader location={location} />
    {!pageNumber ? (
      <div>
          <h2>Homepage</h2>
      </div>
    ) : null}
      <div>
        {children}
      </div>
      <Footer />
  </div>
)

export default HomepageLayout
